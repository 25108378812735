import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description Service to call HTTP requests via Axios
 */
class ApiService {
  /**
   * @description Property to share the Vue instance
   */
  public static vueInstance: App;

  /**
   * @description Initialize Vue Axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = "https://api.asomatic.app";
    //ApiService.vueInstance.axios.defaults.baseURL = "http://local.api.pickaso-reporting.com";
  }

  /**
   * @description Set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
      ] = `Bearer ${JwtService.getToken()}`;
  }

  /**
   * @description Send a GET HTTP request
   * @param resource - The resource URL
   * @param params - Additional Axios request configuration
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig = {} as AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description Send a GET HTTP request
   * @param resource - The resource URL
   * @param slug - An optional slug
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios
      .get(`${resource}/${slug}`)
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  }

  /**
   * @description Send a POST HTTP request
   * @param resource - The resource URL
   * @param params - The payload to send
   * @param config - Optional Axios configuration (e.g., { signal })
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: Record<string, unknown>,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params, config);
  }

  /**
   * @description Send an UPDATE HTTP request
   * @param resource - The resource URL
   * @param slug - An identifier
   * @param params - Axios request configuration
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send a PUT HTTP request
   * @param resource - The resource URL
   * @param params - Axios request configuration
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send a DELETE HTTP request
   * @param resource - The resource URL
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
